import React from 'react';
import PlatsPage from './PlatsPage';

const Entrees = () => {
    return (
        <PlatsPage
            category="dessert"
            title="Nos Desserts"
            description="Commencez votre repas avec une sélection exquise d'entrées."
        />
    );
};

export default Entrees;
