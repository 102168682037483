import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './client.css'; // Inclure tes styles personnalisés
import { getPlats } from '../api/apiService'; // Importer la méthode API pour récupérer les plats

const Plats = () => {
    const [plats, setPlats] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    // Charger les plats depuis l'API
    useEffect(() => {
        const fetchPlats = async () => {
            try {
                const response = await getPlats();
                setPlats(response.data);
            } catch (error) {
                console.error('Erreur lors du chargement des plats :', error);
            }
        };

        fetchPlats();
    }, []);

    // Gestion de la recherche
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filtrage des plats
    const filteredPlats = plats.filter(plat =>
        plat.nom?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <header>
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Rechercher un plat..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    <button onClick={(e) => e.preventDefault()} className="search-button">
                        <i className="fas fa-search"></i>
                    </button>
                </div>

                <h1>Nos Plats</h1>
                <p>Découvrez nos délicieux plats préparés avec soin !</p>
            </header>

            <section className="plats-section">
                <h2>Liste des Plats</h2>
                <div className="plats-items">
                    {filteredPlats.map(plat => (
                        <div className="plat-item" key={plat.id}>
                            <h3>{plat.nom || `Plat #${plat.id}`}</h3>
                            <p>Prix : {plat.prix} FCFA</p>
                            <Link to={`/plat/${plat.id}`} className="btn-details">
                                Voir les détails
                            </Link>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Plats;
