import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh', 
            textAlign: 'center', 
            backgroundColor: '#f9f9f9', 
            padding: '20px' 
        }}>
            <header style={{ marginBottom: '20px' }}>
                <h1 style={{ fontSize: '2.5rem', color: '#ff6b6b' }}>Page Non Trouvée</h1>
                <p style={{ fontSize: '1.2rem', color: '#333' }}>Oups ! La page que vous recherchez n'existe pas ou a été déplacée.</p>
            </header>

            <div>
                <p style={{ fontSize: '1rem', marginBottom: '20px', color: '#555' }}>Vous pouvez retourner à la page d'accueil ou explorer notre menu :</p>
                <Link 
                    to="/" 
                    style={{ 
                        textDecoration: 'none', 
                        padding: '10px 20px', 
                        margin: '5px', 
                        backgroundColor: '#3498db', 
                        color: 'white', 
                        borderRadius: '5px', 
                        fontSize: '1rem' 
                    }}
                >
                    Retour à l'accueil
                </Link>
                
            </div>
        </div>
    );
};

export default NotFound;
