import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, Spin, Alert } from 'antd';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code'; // Import du package QR Code
import { getMenuWithPlats } from '../api/apiService';

const { Title } = Typography;

const MenuDetail = () => {
    const { menuId } = useParams(); // Récupère l'ID du menu depuis l'URL
    const [menu, setMenu] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMenu = async () => {
            try {
                const response = await getMenuWithPlats(menuId);
                setMenu(response.data);
                setLoading(false);
            } catch (err) {
                setError('Erreur lors de la récupération du menu');
                setLoading(false);
            }
        };

        fetchMenu();
    }, [menuId]);

    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return (
            <Alert
                message="Erreur"
                description={error}
                type="error"
                showIcon
                style={{ margin: '20px' }}
            />
        );
    }

    if (!menu) {
        return <p>Aucun menu trouvé.</p>;
    }

    const renderPlats = (categorie) => {
        const plats = menu.plats.filter((plat) => plat.categorie === categorie);
        if (!plats.length) {
            return <p>Aucun plat disponible dans cette catégorie.</p>;
        }

        return plats.map((plat) => (
            <Col key={plat.id} xs={24} sm={12} md={8} lg={6} style={{ marginBottom: '20px' }}>
                <Card
                    hoverable
                    title={plat.nom}
                    cover={<img alt={plat.nom} src={plat.image || '/placeholder.png'} />}
                >
                    <p>{plat.description}</p>
                    <p><strong>Prix:</strong> {plat.prix} €</p>
                </Card>
            </Col>
        ));
    };

    return (
        <div style={{ padding: '20px' }}>
            <Title level={2} style={{ textAlign: 'center', marginBottom: '40px' }}>
                {menu.name}
            </Title>
            <section>
                <Title level={3}>Entrées</Title>
                <Row gutter={16}>{renderPlats('entrée')}</Row>
            </section>
            <section>
                <Title level={3}>Plats Principaux</Title>
                <Row gutter={16}>{renderPlats('resistance')}</Row>
            </section>
            <section>
                <Title level={3}>Desserts</Title>
                <Row gutter={16}>{renderPlats('dessert')}</Row>
            </section>
            <section>
                <Title level={3}>Boissons</Title>
                <Row gutter={16}>{renderPlats('Boisson')}</Row>
            </section>
            <div style={{ marginTop: '40px', textAlign: 'center' }}>
                <Title level={4}>Code QR du Menu</Title>
                <QRCode
                    value={`${window.location.origin}/menu/${menuId}`} // Génère un QR Code pour l'URL actuelle
                    size={100} // Taille du QR Code
                />
            </div>
        </div>
    );
};

export default MenuDetail;
