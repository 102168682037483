import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, Spin, Alert } from 'antd';
import { Link } from 'react-router-dom';
import { getMenusWithPlats } from '../api/apiService';

const { Title } = Typography;

const MenusList = () => {
    const [menus, setMenus] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMenus = async () => {
            try {
                const response = await getMenusWithPlats();
                setMenus(response.data.menus);
                setLoading(false);
            } catch (err) {
                setError('Erreur lors de la récupération des menus');
                setLoading(false);
            }
        };

        fetchMenus();
    }, []);

    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <Spin size="large" />
            </div>
        );
    }

    if (error) {
        return (
            <Alert
                message="Erreur"
                description={error}
                type="error"
                showIcon
                style={{ margin: '20px' }}
            />
        );
    }

    if (!menus.length) {
        return <p>Aucun menu trouvé.</p>;
    }

    return (
        <div style={{ padding: '20px' }}>
            <Title level={2} style={{ textAlign: 'center', marginBottom: '40px' }}>
                Liste des Menus
            </Title>
            {menus.map((menu) => (
                <div key={menu.id} style={{ marginBottom: '40px' }}>
                    <Link to={`/menu/${menu.id}`}>
                        <Title level={3} style={{ color: '#1890ff', cursor: 'pointer' }}>
                            {menu.name}
                        </Title>
                    </Link>
                    <Row gutter={16}>
                        {menu.Plats.slice(0, 4).map((plat) => ( // Affiche seulement les 4 premiers plats
                            <Col key={plat.id} xs={24} sm={12} md={8} lg={6} style={{ marginBottom: '20px' }}>
                                <Card
                                    hoverable
                                    title={plat.name}
                                    cover={<img alt={plat.name} src={plat.image || '/placeholder.png'} />}
                                >
                                    <p>{plat.description}</p>
                                    <p><strong>Prix:</strong> {plat.price} FCFA</p>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </div>
    );
};

export default MenusList;
