import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PlatSection from './admin/platSection';
import Profile from './admin/profile';
import Client from './client/Client'
// import MenuDetails from './client/MenuDetail';
import Plats from './client/Plats';
import PlatDetail from './client/PlatDetail';
import {Login} from './auth/Login'
import Register from './auth/Register';
import Welcome from './client/welcome';
import Admin from './admin/Admin';
import ProtectedRoute from './api/ProtectedRoute';
import MenuPage from './client/MenuPage';
import MenuDetail from './client/MenuDetail';
import MenuSection from './admin/MenuSection';
import AddPlatToMenu from './admin/AddPlatTomenu';
import Desserts from './client/Desserts';
import HomeSection from './admin/HomeSection';
import NotFound from './client/NotFound';
import Entrees from './client/Entrees';
import Boissons from './client/Boissons';
import Resistances from './client/Resistance';
import Buffet from './client/Buffet';

function App() {
  
  const token = localStorage.getItem('token');
  if(!token){
    return <div><Content/></div>
  }
  else{
    return (

      <div>
        {/* <Header/> */}
        <Admin>
        <Content/>
        </Admin>
        <div style={{display: "flex", flexDirection: "column", flex: 1, height: "100vh"}}>
          {/* <Content/> */}
          {/* <Register/> */}
          {/* <Login/> */}
          {/* <Client/> */}
        </div>
      </div>

  
  );
  }
  
}


export function Content(){
  return (
    <div>
      <Routes>
        <Route path='/' element={<Welcome/>}/>
        {/* <Route path='/admin/menus' element={<ProtectedRoute><MenuSection/></ProtectedRoute>}/> */}
        <Route path='/admin/buffet' element={<ProtectedRoute><MenuSection/></ProtectedRoute>}/>
        <Route path='/admin/add-plats-to-buffet' element={<ProtectedRoute><AddPlatToMenu/></ProtectedRoute>}/>
        <Route path='/admin/plats' element={<ProtectedRoute><PlatSection/></ProtectedRoute>}/>
        <Route path='/admin/profil' element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
        <Route path='/logout' element={<div>Se déconnecter</div>}></Route>
        <Route path="/platss" element={<Plats />} />
        <Route path="/plat/:platId" element={<PlatDetail />} /> {/* Route pour les détails des plats */}
        <Route path="/menu/" element={<MenuPage />} />
        <Route path="/menu/:menuId" element={<MenuDetail />} />
        <Route path="/admin/login" element={<Login/>} />
        <Route path="/admin/register" element={<Register/>} />
        <Route path="/admin/home" element={<HomeSection/>} />
        <Route path="/desserts" element={<Desserts/>} />
        <Route path="/entrees" element={<Entrees/>} />
        <Route path="/plats-principaux" element={<Resistances/>} />
        <Route path="/boissons" element={<Boissons/>} />
        <Route path="*" element={<NotFound/>} />
        <Route path="/buffet" element={<Buffet/>} />
      </Routes>
    </div>
  );
}

export default App;
