import React, { useState, useEffect } from "react";
import { Table, Button, Input, Checkbox, Form, Upload, message, Modal } from "antd";
import { UploadOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getPlats, createPlat, updatePlat, deletePlat } from "../api/apiService";

const { TextArea } = Input;

const Plat = () => {
  const [plats, setPlats] = useState([]);
  const [form] = Form.useForm();
  const [editingPlat, setEditingPlat] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);


  useEffect(() => {
    fetchPlats();
  }, []);

  const fetchPlats = async () => {
    setLoading(true);
    try {
      const response = await getPlats();
      setPlats(response.data);
    } catch (error) {
      message.error("Impossible de charger les plats.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    const data = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === "image" && fileList.length > 0) {
          data.append("image", fileList[0].originFileObj); // Ajoute l'image comme fichier brut
      } else if (values[key] !== undefined && values[key] !== null) {
          data.append(key, values[key]);
      }
    });

    try {
      if (editingPlat) {
        await updatePlat(editingPlat.id, data);
        message.success("Plat mis à jour avec succès !");
      } else {
        await createPlat(data);
        message.success("Plat ajouté avec succès !");
      }
      fetchPlats();
      resetForm();
    } catch (error) {
      message.error("Impossible de sauvegarder le plat.");
    }
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  
  const handleEdit = (plat) => {
    setEditingPlat(plat);
    form.setFieldsValue({
      ...plat,
      disponible: plat.disponible,
    });
  };

  const handleDelete = async (id) => {
    Modal.confirm({
      title: "Confirmer la suppression",
      content: "Êtes-vous sûr de vouloir supprimer ce plat ?",
      okText: "Oui",
      cancelText: "Non",
      onOk: async () => {
        try {
          await deletePlat(id);
          message.success("Plat supprimé avec succès !");
          fetchPlats();
        } catch (error) {
          message.error("Impossible de supprimer le plat.");
        }
      },
    });
  };

  const resetForm = () => {
    setEditingPlat(null);
    form.resetFields();
  };

  const columns = [
    { title: "Nom", dataIndex: "nom", key: "nom" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "Prix", dataIndex: "prix", key: "prix", render: (text) => `${text} FCFA` },
    { title: "Disponible", dataIndex: "disponible", key: "disponible", render: (val) => (val ? "Oui" : "Non") },
    { title: "Catégorie", dataIndex: "categorie", key: "categorie" },
    {
      title: "Image",
      dataIndex: "image_url",
      key: "image_url",
      render: (url) => url && <img src={url} alt="plat" style={{ width: "80px", height: "80px", objectFit: "cover" }} />,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <>
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} style={{ marginRight: 8 }} />
          <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.id)} danger />
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <h1>Gestion des Plats</h1>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        style={{
          marginBottom: "20px",
          padding: "20px",
          border: "1px solid #f0f0f0",
          borderRadius: "8px",
          backgroundColor: "#ffffff",
        }}
      >
        <Form.Item label="Nom" name="nom" rules={[{ required: true, message: "Veuillez entrer un nom." }]}>
          <Input placeholder="Nom du plat" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea placeholder="Description du plat" rows={3} />
        </Form.Item>
        <Form.Item label="Prix (F CFA)" name="prix" rules={[{ required: true, message: "Veuillez entrer un prix." }]}>
          <Input type="number" placeholder="Prix du plat" />
        </Form.Item>
        <Form.Item name="disponible" valuePropName="checked">
          <Checkbox>Disponible</Checkbox>
        </Form.Item>
        <Form.Item label="Catégorie" name="categorie">
          <Input placeholder="Catégorie du plat" />
        </Form.Item>
        <Form.Item label="Image" name="image">
          <Upload beforeUpload={() => true}
              fileList={fileList}
              onChange={handleFileChange}
            >
            <Button icon={<UploadOutlined />}>Télécharger une image</Button>
          </Upload>
        </Form.Item>
        <Button type="primary" htmlType="submit" style={{ marginRight: 8 }}>
          {editingPlat ? "Modifier" : "Ajouter"}
        </Button>
        {editingPlat && (
          <Button onClick={resetForm} type="default">
            Annuler
          </Button>
        )}
      </Form>

      <Table
        dataSource={plats}
        columns={columns}
        rowKey="id"
        loading={loading}
        bordered
        style={{ backgroundColor: "#ffffff" }}
      />
    </div>
  );
};

export default Plat;
