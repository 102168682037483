import axios from 'axios';

const API_URL = 'http://127.0.0.1:4000/api/users';

export const register = async (nom, email, mot_de_passe) => {
    try {
        const response = await axios.post(`${API_URL}/register`, {
            nom,
            email,
            mot_de_passe,
        });
        return response.data;
    } catch (error) {
        console.error(error.response?.data || error.message);
        throw error.response?.data || error.message;
    }
};

export const login = async (email, mot_de_passe) => {
    try {
        const response = await axios.post(`${API_URL}/login`, {
            email,
            mot_de_passe,
        });
        return response.data;
    } catch (error) {
        console.error(error.response?.data || error.message);
        throw error.response?.data || error.message;
    }
};

export const getUserProfile = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/profile`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        console.error(error.response?.data || error.message);
        throw error.response?.data || error.message;
    }
};
