import React, { useEffect } from 'react';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import { FaUtensils, FaLeaf, FaIceCream, FaWineGlassAlt  } from 'react-icons/fa';
import { IoFastFoodSharp } from "react-icons/io5";

import 'aos/dist/aos.css';
import './welcome.css'; // Fichier CSS à personnaliser


const Welcome = () => {
    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    return (
        <div className="welcome-container">
            <Header />
            <Footer />
        </div>
    );
};

const Header = () => {
    return (
        <header className="welcome-header">
            <h1 data-aos="fade-up"
                style={{
                    color: "white"
                }}
            >Bienvenue dans notre restaurant</h1>
            <p data-aos="fade-up" data-aos-delay="300" 
                style={{
                    color: "white"
                }}
            >
                Explorez nos spécialités culinaires et laissez-vous tenter par nos délicieux plats.
            </p>
            <div className="menu-grid">
                <Card
                    to="/entrees"
                    icon={<FaLeaf />}
                    title="Entrées"
                    delay="400"
                />
                <Card
                    to="/plats-principaux"
                    icon={<FaUtensils />}
                    title="Plats Principaux"
                    delay="500"
                />
                <Card
                    to="/desserts"
                    icon={<FaIceCream />}
                    title="Desserts"
                    delay="600"
                />
                <Card
                    to="/boissons"
                    icon={<FaWineGlassAlt />}
                    title="Boissons"
                    delay="600"
                />
                <Card
                    to="/buffet"
                    icon={<IoFastFoodSharp  />}
                    title="Buffet"
                    delay="600"
                />
            </div>
        </header>
    );
};

const Card = ({ to, icon, title, delay }) => {
    return (
        <Link
            to={to}
            className="menu-card"
            data-aos="fade-up"
            data-aos-delay={delay}
        >
            <div className="card-icon">{icon}</div>
            <h3 className="card-title">{title}</h3>
        </Link>
    );
};

export const Footer = () => {
    return (
        <footer className="welcome-footer">
            <p>&copy; 2024 Restaurant de Lomé. <Link to="/admin/login" className='admin'>Tous droits réservés.</Link></p>
            
        </footer>
    );
};

export default Welcome;
