import React from 'react';
import PlatsPage from './PlatsPage';

const Boissons = () => {
    return (
        <PlatsPage
            category="boisson"
            title="Nos Boissons"
            description="Rafraîchissez-vous avec notre sélection de boissons."
        />
    );
};

export default Boissons;
