import React, { useEffect, useState } from 'react';
import { getMenuWithPlats } from '../api/apiService'; // API pour récupérer le menu et ses plats
import './buffet.css'; // Inclure les styles définis
import { Link } from 'react-router-dom';
import { Footer } from './welcome';

const Buffet = ({ menuId, title = "Buffet de la Semaine", description }) => {
    const [menu, setMenu] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchMenuWithPlats = async () => {
            try {
                const response = await getMenuWithPlats(2);
                setMenu(response.data.menu);
                setError(null);
            } catch (error) {
                console.error("Erreur lors de la récupération du menu :", error);
                setError('Impossible de charger le menu. Veuillez réessayer plus tard.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchMenuWithPlats();
    }, [menuId]);

    return (
        <div className="buffet-container">
            {/* En-tête */}
            <header className="buffet-header">
                <h1>{title}</h1>
                <p>{description}</p>
            </header>

            {/* Indicateur de chargement */}
            {isLoading && <p className="loading-message">Chargement en cours...</p>}

            {/* Message d'erreur */}
            {error && <p className="error-message">{error}</p>}

            {/* Liste des plats */}
            {menu && menu.Plats && menu.Plats.length > 0 ? (
                <section className="buffet-list">
                    {menu.Plats.map((plat) => (
                        <div className="buffet-item" key={plat.id}>
                            <img 
                                src={plat.image_url ? `http://localhost:4000${plat.image_url}` : 'https://via.placeholder.com/100'} 
                                alt={plat.nom} 
                                className="buffet-image" 
                            />
                            <div className="buffet-details">
                                <h3 className="buffet-name">{plat.nom}</h3>
                                <p className="buffet-description">{plat.description}</p>
                                <p className="buffet-price">{plat.prix} FCFA</p>
                            </div>
                            <Link to={`/plat/${plat.id}`} className="btn-order">
                                Voir les détails
                            </Link>
                        </div>
                    ))}
                </section>
            ) : (
                !isLoading && <p className="no-results">Aucun plat disponible pour ce menu.</p>
            )}

            <Footer />
        </div>
    );
};

export default Buffet;
