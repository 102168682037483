import React from 'react';
import PlatsPage from './PlatsPage';

const Entrees = () => {
    return (
        <PlatsPage
            category="entrée"
            title="Nos Entrées"
            description="Commencez votre repas avec une sélection exquise d'entrées."
        />
    );
};

export default Entrees;
