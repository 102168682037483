import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import { register } from '../api/utilisateur';

import 'aos/dist/aos.css';

export const Register = () => {
    const [nom, setNom] = useState('');
    const [email, setEmail] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease-in-out',
            once: true,
        });
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await register(nom, email, motDePasse);
            setMessage('Inscription réussie. Bienvenue ID : ' + data.utilisateurID);
            navigate('/admin/login');
        } catch (error) {
            setMessage(error.error || "Erreur lors de l'inscription.");
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f2f5',
        }}>
            <div style={{
                width: '400px',
                backgroundColor: 'white',
                padding: '30px',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
            }}>
                <h1 style={{ marginBottom: '20px', fontSize: '1.8em', color: '#333' }}>
                    Créez un compte
                </h1>
                
                <form onSubmit={handleSubmit}>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="text"
                            placeholder="Nom d'utilisateur"
                            value={nom}
                            onChange={(e) => setNom(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '6px',
                                border: '1px solid #ccc',
                            }}
                            required
                        />
                    </div>
                    <div style={{ marginBottom: '15px' }}>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '6px',
                                border: '1px solid #ccc',
                            }}
                            required
                        />
                    </div>
                    <div style={{ marginBottom: '25px' }}>
                        <input
                            type="password"
                            placeholder="Mot de passe"
                            value={motDePasse}
                            onChange={(e) => setMotDePasse(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '10px',
                                borderRadius: '6px',
                                border: '1px solid #ccc',
                            }}
                            required
                        />
                    </div>
                    <button
                        type="submit"
                        style={{
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            fontSize: '1em',
                            fontWeight: 'bold',
                            border: 'none',
                            borderRadius: '6px',
                            cursor: 'pointer',
                        }}
                    >
                        Inscription
                    </button>
                </form>
                {message && (
                    <p style={{
                        marginTop: '15px',
                        color: message.includes('réussie') ? 'green' : 'red',
                    }}>
                        {message}
                    </p>
                )}
                <p style={{ marginTop: '20px', color: '#555' }}>
                    Déjà inscrit ? <Link to="/admin/login" style={{ color: '#4CAF50', fontWeight: 'bold' }}>Connectez-vous</Link>
                </p>
            </div>
        </div>
    );
};

export default Register;
