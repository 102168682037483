import React, { useState } from 'react';
import { login } from '../api/utilisateur';
import { Link, useNavigate } from 'react-router-dom';

export const Login = () => {
    const [email, setEmail] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const data = await login(email, motDePasse);
            localStorage.setItem('token', data.token);
            setMessage('Connexion réussie. Bienvenue ID : ' + data.utilisateurID);
            navigate('/admin/home');
        } catch (error) {
            setMessage(error.error || 'Erreur lors de la connexion.');
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#f0f2f5',
        }}>
            <div style={{
                width: '400px',
                backgroundColor: 'white',
                padding: '30px',
                borderRadius: '8px',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                textAlign: 'center',
            }}>
                <h1 style={{ marginBottom: '20px', fontSize: '1.8em', color: '#333' }}>
                    Connexion
                </h1>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '15px',
                            borderRadius: '6px',
                            border: '1px solid #ccc',
                        }}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Mot de passe"
                        value={motDePasse}
                        onChange={(e) => setMotDePasse(e.target.value)}
                        style={{
                            width: '100%',
                            padding: '10px',
                            marginBottom: '15px',
                            borderRadius: '6px',
                            border: '1px solid #ccc',
                        }}
                        required
                    />
                    <button
                        type="submit"
                        style={{
                            width: '100%',
                            padding: '10px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            fontSize: '1em',
                            fontWeight: 'bold',
                            border: 'none',
                            borderRadius: '6px',
                            cursor: 'pointer',
                        }}
                    >
                        Se connecter
                    </button>
                </form>
                <p>Pas encore de compte ? <Link to="/admin/register" style={{ color: '#4CAF50', fontWeight: 'bold' }}>Inscrivez-vous ici</Link></p>
                {message && (
                    <p style={{
                        marginTop: '15px',
                        color: message.includes('réussie') ? 'green' : 'red',
                    }}>
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
};

export default Login;
