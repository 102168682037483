import React, { useState } from 'react';
import './styles.css';

function Profile() {
  // États pour les informations utilisateur
  const [name, setName] = useState('John Doe');
  const [email, setEmail] = useState('johndoe@example.com');
  const [isEditing, setIsEditing] = useState(false);

  // Gestion de l'édition
  const toggleEdit = () => setIsEditing(!isEditing);

  const handleSave = () => {
    setIsEditing(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f4f6f9',
      }}
    >
      <div
        style={{
          padding: '30px',
          width: '400px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          textAlign: 'center',
        }}
      >
        <h2 style={{ color: '#333', marginBottom: '20px' }}>Profil utilisateur</h2>
        <div style={{ marginBottom: '20px', textAlign: 'left' }}>
          <p>
            <strong>Nom :</strong>{' '}
            {isEditing ? (
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  padding: '8px',
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '6px',
                }}
              />
            ) : (
              name
            )}
          </p>
          <p>
            <strong>Email :</strong>{' '}
            {isEditing ? (
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  padding: '8px',
                  width: '100%',
                  border: '1px solid #ddd',
                  borderRadius: '6px',
                }}
              />
            ) : (
              email
            )}
          </p>
        </div>
        {isEditing ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <button
              onClick={handleSave}
              style={{
                padding: '10px 20px',
                backgroundColor: '#4CAF50',
                color: '#fff',
                border: 'none',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
            >
              Sauvegarder
            </button>
            <button
              onClick={toggleEdit}
              style={{
                padding: '10px 20px',
                backgroundColor: '#f44336',
                color: '#fff',
                border: 'none',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
            >
              Annuler
            </button>
          </div>
        ) : (
          <button
            onClick={toggleEdit}
            style={{
              padding: '10px 20px',
              backgroundColor: '#008CBA',
              color: '#fff',
              border: 'none',
              borderRadius: '6px',
              cursor: 'pointer',
            }}
          >
            Modifier le profil
          </button>
        )}
      </div>
    </div>
  );
}

export default Profile;
