import React, { useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

function HomeSection() {
  const canvasRef = useRef();

  const downloadQRCode = () => {
    const canvas = canvasRef.current;
    const url = canvas.toDataURL('image/png'); // Génère une URL pour l'image en format PNG
    const link = document.createElement('a');
    link.href = url;
    link.download = 'qr_code_restaurant.png'; // Nom du fichier à télécharger
    link.click();
  };

  return (
    <div style={{ padding: '20px' }} className="App">
      <h2>Restaurant de Lomé</h2>
      <div className="qr-code-container">
        <h3>Code QR du restaurant</h3>
        <QRCodeCanvas
          ref={canvasRef}
          value={`https://19e4-196-169-17-236.ngrok-free.app/`}
          size={200} // Taille en pixels
        />

      </div>
      <button
          onClick={downloadQRCode}
          style={{
            marginTop: '10px',
            padding: '10px 20px',
            backgroundColor: '#007BFF',
            color: '#FFF',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Télécharger le QR Code
        </button>
    </div>
  );
}

export default HomeSection;
