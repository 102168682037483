import React, { useEffect, useState } from 'react';
import { getPlatByCategory } from '../api/apiService'; // API pour récupérer les plats
import './plats.css'; // Inclure les styles définis
import { Link } from 'react-router-dom';
import { Footer } from './welcome';

const PlatsPage = ({ category, title, description }) => {
    const [plats, setPlats] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchPlats = async () => {
            try {
                const response = await getPlatByCategory(category); // Appel API pour la catégorie
                setPlats(response.data);
            } catch (error) {
                console.error(`Erreur lors de la récupération des ${category} :`, error);
            }
        };

        fetchPlats();
    }, [category]);

    // Gestion de la recherche
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filtrage des plats par recherche
    const filteredPlats = plats.filter((plat) =>
        plat.nom.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="plat-container">
            {/* En-tête */}
            <header className="plat-header">
                <h1>{title}</h1>
                <p>{description}</p>
                <div className="search-container">
                    <input
                        type="text"
                        placeholder={`Rechercher un plat...`}
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                </div>
            </header>

            {/* Liste des plats */}
            <section className="plat-list">
                {filteredPlats.length > 0 ? (
                    filteredPlats.map((plat) => (
                        <div className="plat-item" key={plat.id}>
                            <img
                                src={`http://localhost:4000${plat.image_url}` || 'https://via.placeholder.com/100'}
                                alt={plat.nom}
                                className="plat-image"
                            />
                            <div className="plat-details">
                                <h3 className="plat-name">{plat.nom}</h3>
                                <p className="plat-description">{plat.description}</p>
                                <p className="plat-price">{plat.prix} FCFA</p>
                            </div>
                            <Link to={`/plat/${plat.id}`} className="btn-order">
                                Voir les détails
                            </Link>
                        </div>
                    ))
                ) : (
                    <p className="no-results">Aucun plat trouvé.</p>
                )}
            </section>

            <Footer />
        </div>
    );
};

export default PlatsPage;
