import axios from 'axios';

const API_URL = 'http://localhost:4000/api'; // URL du backend

// Instance Axios
const api = axios.create({
    baseURL: API_URL,
});

// Intercepteur pour ajouter le token d'authentification
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
}, (error) => Promise.reject(error));

// Fonctions spécifiques aux Plats
export const createPlat = async (platData) => {
    return await api.post('/plats/creer', platData, {headers: {
        'Content-Type': 'multipart/form-data',
    }});
};

export const updatePlat = async (platId, platData) => {
    return await api.put(`/plats/${platId}`, platData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

// Fonctions générales
export const getPlats = () => api.get('/plats');
export const getPlatById = (platId) => api.get(`/plats/${platId}`);
export const deletePlat = (platId) => api.delete(`/plats/${platId}`);
export const getPlatByCategory = (category) => api.get(`/plats/categorie/${category}`);

// Fonctions pour les Menus
export const getMenus = () => api.get('/menus');
export const getMenuById = (menuId) => api.get(`/menus/${menuId}`);
export const addMenu = (menuData) => api.post('/menus/create', menuData);
export const updateMenu = (menuId, menuData) => api.put(`/menus/${menuId}`, menuData);
export const deleteMenu = (menuId) => api.delete(`/menus/${menuId}`);
export const getMenuWithPlats = (menuId) => api.get(`/menus/${menuId}`);
export const getMenusWithPlats = () => api.get('/menus-with-plats');
export const addPlatToMenu = (menuId, platIds) => api.post(`/menus/${menuId}/addPlats`, { platIds });
// export const deletePlatToMenu = (menuId) => api.delete(``)


//avis
export const createAvisForPlat = (platId, avisData) => api.post(`/plats/${platId}/avis`, avisData);

export default api;
