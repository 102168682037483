import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "antd";
import {
  HomeOutlined,
  MenuOutlined,
  UserOutlined,
  CoffeeOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";

function Header() {
  return (
    <div
      style={{
        height: 60,
        backgroundColor: "#1E293B", // Gris foncé bleuté
        color: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 20px",
        fontWeight: "bold",
        fontSize: "1.3rem",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div>Admin Dashboard</div>
      <div style={{ fontSize: "0.9rem", color: "#CBD5E1" }}>Bienvenue !</div>
    </div>
  );
}

function SideMenu() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: 250,
        background: "#F8FAFC",
        height: "100vh",
        borderRight: "1px solid #E2E8F0",
        boxShadow: "2px 0px 4px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Menu
        onClick={({ key }) => {
          if (key === "logout") {
            // Logic for logout
            console.log("Logout action triggered!");
          } else {
            navigate(key);
          }
        }}
        defaultSelectedKeys={[window.location.pathname]}
        mode="inline"
        style={{
          height: "100%",
          backgroundColor: "transparent",
          color: "#334155",
        }}
        items={[
          {
            label: "Home",
            key: "/admin/home",
            icon: <HomeOutlined style={{ fontSize: "16px", color: "#0F172A" }} />,
          },
          // {
          //   label: "Restaurants",
          //   key: "/admin/restaurants",
          //   icon: <CoffeeOutlined style={{ fontSize: "16px", color: "#0F172A" }} />,
          // },
          {
            label: "Buffet",
            icon: <MenuOutlined style={{ fontSize: "16px", color: "#0F172A" }} />,
            children: [
              { label: "Gérer le menu", key: "/admin/buffet" },
              { label: "Ajouter un plat", key: "/admin/add-plats-to-buffet" },
            ],
          },
          { label: "Plat", key: "/admin/plats" },
          {
            label: "Profil",
            key: "/admin/profil",
            icon: <UserOutlined style={{ fontSize: "16px", color: "#0F172A" }} />,
          },
          {
            label: "Se déconnecter",
            key: "/admin/login",
            icon: <PoweroffOutlined style={{ fontSize: "16px", color: "#DC2626" }} />,
            danger: true,
          },
        ]}
      />
    </div>
  );
}

function Admin({ children }) {
  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Header />
      <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
        <SideMenu />
        <div
          style={{
            flex: 1,
            padding: "20px",
            backgroundColor: "#F1F5F9", // Gris doux pour le fond principal
            overflowY: "auto",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default Admin;
