// src/components/AddPlatToMenu.jsx
import React, { useEffect, useState } from 'react';
import { getMenus, addPlatToMenu, getMenuWithPlats, getPlats } from '../api/apiService';
import './styles.css';

const AddPlatToMenu = () => {
    const [menusList, setMenusList] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState('');
    const [platsList, setPlatsList] = useState([]);
    const [availablePlats, setAvailablePlats] = useState([]);
    const [selectedPlats, setSelectedPlats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showForm, setShowForm] = useState(false);

    // Charger les menus et les plats disponibles
    useEffect(() => {
        fetchMenus();
        fetchAvailablePlats();
    }, []);

    // Charger les plats associés au menu sélectionné
    useEffect(() => {
        if (selectedMenu) fetchPlatsForMenu(selectedMenu);
        else setPlatsList([]);
    }, [selectedMenu]);

    // Charger la liste des menus
    const fetchMenus = async () => {
        try {
            setLoading(true);
            const response = await getMenus();
            setMenusList(response.data || []);
        } catch (err) {
            setError('Erreur lors du chargement des menus.');
        } finally {
            setLoading(false);
        }
    };

    // Charger les plats d'un menu spécifique
    const fetchPlatsForMenu = async (menuId) => {
        try {
            setLoading(true);
            const response = await getMenuWithPlats(menuId);
            setPlatsList(response.data?.menu?.Plats || []);
        } catch (err) {
            setError('Erreur lors du chargement des plats du menu.');
        } finally {
            setLoading(false);
        }
    };

    // Charger tous les plats disponibles
    const fetchAvailablePlats = async () => {
        try {
            const response = await getPlats();
            setAvailablePlats(response.data || []);
        } catch (err) {
            setError('Erreur lors du chargement des plats disponibles.');
        }
    };

    // Ajouter des plats au menu
    const handleAddPlats = async (e) => {
        e.preventDefault();
        console.log("Ajout des plats :", { selectedMenu, selectedPlats }); // Log pour débogage

        if (!selectedMenu) {
            setError('Veuillez sélectionner un menu.');
            return;
        }

        if (selectedPlats.length === 0) {
            setError('Veuillez sélectionner au moins un plat à ajouter.');
            return;
        }

        try {
            setLoading(true);
            setError(null);
            setSuccess(null);
            await addPlatToMenu(selectedMenu, selectedPlats); // Passez les plats sélectionnés
            fetchPlatsForMenu(selectedMenu); // Rechargez les plats du menu
            setSelectedPlats([]); // Réinitialisez la sélection
            setSuccess('Plats ajoutés avec succès au menu.');
            setShowForm(false);
        } catch (err) {
            console.error('Erreur lors de l’ajout des plats :', err.response || err.message);
            setError(err.response?.data?.error || 'Erreur lors de l’ajout des plats au menu.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <h2>Gestion des Menus et Plats</h2>

            {/* Message d'erreur ou de succès */}
            {error && <div className="error-message">{error}</div>}
            {success && <div className="success-message">{success}</div>}

            {/* Sélection du menu */}
            <div className="section">
                <label>
                    Sélectionnez un menu :
                    <select
                        value={selectedMenu}
                        onChange={(e) => setSelectedMenu(e.target.value)}
                        disabled={loading}
                    >
                        <option value="">-- Choisir un menu --</option>
                        {menusList.map((menu) => (
                            <option key={menu.id} value={menu.id}>
                                {menu.nom}
                            </option>
                        ))}
                    </select>
                </label>
            </div>

            {/* Liste des plats du menu sélectionné */}
            {selectedMenu && (
                <div className="section">
                    <h3>Plats associés au menu</h3>
                    <ul>
                        {platsList.length > 0 ? (
                            platsList.map((plat) => (
                                <li key={plat.id}>
                                    {plat.nom} - {plat.prix} FCFA
                                </li>
                            ))
                        ) : (
                            <p>Aucun plat associé à ce menu.</p>
                        )}
                    </ul>
                    <button
                        onClick={() => setShowForm(!showForm)}
                        disabled={loading}
                        className="action-button"
                    >
                        {showForm ? 'Annuler' : '+ Ajouter des Plats'}
                    </button>
                </div>
            )}

            {/* Formulaire pour ajouter des plats */}
            {showForm && (
                <form onSubmit={handleAddPlats} className="form-section">
                    <label>
                        Sélectionnez des plats à ajouter :
                        <select
                            multiple
                            value={selectedPlats}
                            onChange={(e) =>
                                setSelectedPlats(Array.from(e.target.selectedOptions, (option) => parseInt(option.value)))
                            }
                        >
                            {availablePlats.map((plat) => (
                                <option key={plat.id} value={plat.id}>
                                    {plat.nom} - {plat.prix} FCFA
                                </option>
                            ))}
                        </select>
                    </label>
                    <button type="submit" disabled={loading}>
                        Ajouter
                    </button>
                </form>
            )}
        </div>
    );
};

export default AddPlatToMenu;
