import React, { useEffect, useState } from 'react';
import { getMenus, addMenu, deleteMenu, updateMenu } from '../api/apiService';
import './styles.css';

const MenuSection = () => {
    const [menu, setMenu] = useState({ nom: '' });
    const [editingMenu, setEditingMenu] = useState(null);
    const [menusList, setMenusList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchMenus();
    }, []);

    // Charger les menus depuis l'API
    const fetchMenus = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await getMenus();
            setMenusList(response.data || []);
        } catch (err) {
            setError("Erreur lors du chargement des menus.");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    // Gérer les champs du formulaire
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setMenu((prev) => ({ ...prev, [name]: value }));
    };

    // Ajouter un menu
    const handleAddMenu = async (e) => {
        e.preventDefault();
        try {
            setError(null);
            await addMenu(menu);
            fetchMenus();
            setMenu({ nom: '' });
        } catch (err) {
            setError("Erreur lors de l'ajout du menu.");
            console.error(err);
        }
    };

    // Modifier un menu
    const handleEditMenu = (menu) => {
        setEditingMenu(menu);
        setMenu({ nom: menu.nom });
    };

    const handleUpdateMenu = async (e) => {
        e.preventDefault();
        try {
            setError(null);
            await updateMenu(editingMenu.id, menu);
            fetchMenus();
            setEditingMenu(null);
            setMenu({ nom: '' });
        } catch (err) {
            setError("Erreur lors de la mise à jour du menu.");
            console.error(err);
        }
    };

    // Annuler l'édition
    const handleCancelEdit = () => {
        setEditingMenu(null);
        setMenu({ nom: '' });
    };

    // Supprimer un menu
    const handleDeleteMenu = async (id) => {
        try {
            const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ce menu ?");
            if (isConfirmed) {
                await deleteMenu(id);
                fetchMenus();
            }
        } catch (err) {
            setError("Erreur lors de la suppression du menu.");
            console.error(err);
        }
    };

    return (
        <div className="menu-section">
            <h2>Gestion des Menus</h2>

            {/* Formulaire d'ajout/mise à jour */}
            <form onSubmit={editingMenu ? handleUpdateMenu : handleAddMenu} className="menu-form">
                <input
                    type="text"
                    name="nom"
                    value={menu.nom}
                    onChange={handleInputChange}
                    placeholder="Nom du menu"
                    required
                />
                <button type="submit">{editingMenu ? 'Mettre à jour' : 'Ajouter'}</button>
                {editingMenu && (
                    <button type="button" onClick={handleCancelEdit}>
                        Annuler
                    </button>
                )}
            </form>

            {/* Erreur */}
            {error && <div className="error">{error}</div>}

            {/* Liste des menus */}
            <div className="menu-list">
                <h3>Liste des Menus</h3>
                {loading ? (
                    <p>Chargement...</p>
                ) : menusList.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>Nom</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {menusList.map((menu) => (
                                <tr key={menu.id}>
                                    <td>{menu.nom}</td>
                                    <td>
                                        <button onClick={() => handleEditMenu(menu)}>Modifier</button>
                                        <button onClick={() => handleDeleteMenu(menu.id)}>Supprimer</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Aucun menu disponible.</p>
                )}
            </div>
        </div>
    );
};

export default MenuSection;
