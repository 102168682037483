import React from 'react';
import PlatsPage from './PlatsPage';

const Resistances = () => (
    <PlatsPage
        category="resistance"
        title="Nos Résistances"
        description="Savourez nos plats de résistance, riches et variés."
    />
);

export default Resistances;
