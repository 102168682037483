import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPlatById, createAvisForPlat } from "../api/apiService";
import "./platDetail.css";

const PlatDetail = () => {
    const { platId } = useParams();
    const [plat, setPlat] = useState(null);
    const [error, setError] = useState(null);
    const [avis, setAvis] = useState({ note: 0, commentaire: "" });
    const [avisSuccess, setAvisSuccess] = useState(null);
    const [avisError, setAvisError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchPlat = async () => {
            try {
                const response = await getPlatById(platId);
                setPlat(response.data);
            } catch (err) {
                const errorMessage = err.response?.data?.message || "Impossible de charger les détails du plat.";
                setError(errorMessage);
            }
        };

        fetchPlat();
    }, [platId]);

    const handleStarClick = (note) => {
        setAvis({ ...avis, note });
    };

    const handleAvisChange = (e) => {
        const { name, value } = e.target;
        setAvis({ ...avis, [name]: value });
    };

    const handleAvisSubmit = async (e) => {
        e.preventDefault();
        if (!avis.note || !avis.commentaire) {
            setAvisError("Veuillez attribuer une note et un commentaire avant de soumettre votre avis.");
            return;
        }

        setIsLoading(true);
        try {
            setAvis({ note: 0, commentaire: "" });
            setAvisSuccess("Votre avis a été soumis avec succès !");
        } catch (err) {
            setAvisError("Une erreur s'est produite lors de l'envoi de votre avis.");
        } finally {
            setIsLoading(false);
        }
    };

    if (error) {
        return (
            <div className="error-container">
                <h2>Erreur</h2>
                <p>{error}</p>
            </div>
        );
    }

    if (!plat) {
        return <p className="loading-message">Chargement des détails du plat...</p>;
    }

    return (
        <div className="plat-detail-container">
            <header className="plat-detail-header">
                <h1>{plat.nom}</h1>
                <p className="plat-category">
                    {plat.categorie || "Catégorie inconnue"}
                </p>
            </header>

            <div className="plat-detail-content">
                <img
                    src={`http://localhost:4000${plat.image_url}`}
                    alt={plat.nom}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/300";
                    }}
                    className="plat-detail-image"
                />
                <div className="plat-detail-info">
                    <h3>Description</h3>
                    <p>{plat.description || "Aucune description disponible."}</p>
                    <h3 className="plat-price">Prix : {plat.prix} FCFA</h3>
                    <p>
                       {" "}
                        <span className={plat.disponible ? "available" : "unavailable"}>
                            {plat.disponible ? "Disponible" : "Non disponible"}
                        </span>
                    </p>
                </div>
            </div>

            <div className="plat-avis-section">
                <h2>Donner votre avis</h2>
                <form onSubmit={handleAvisSubmit} className="avis-form">
                    
                    <div className="form-group star-rating">
                        <label>Note :</label>
                        <div className="stars">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <span
                                    key={star}
                                    className={`star ${star <= avis.note ? "filled" : ""}`}
                                    onClick={() => handleStarClick(star)}
                                >
                                    ★
                                </span>
                            ))}
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="commentaire">Commentaire :</label>
                        <textarea
                            id="commentaire"
                            name="commentaire"
                            value={avis.commentaire}
                            onChange={handleAvisChange}
                            rows="4"
                            required
                        />
                    </div>
                    <button type="submit" className="submit-avis-button" disabled={isLoading}>
                        {isLoading ? "Envoi en cours..." : "Soumettre"}
                    </button>
                </form>

                {avisSuccess && <p className="avis-message success">{avisSuccess}</p>}
                {avisError && <p className="avis-message error">{avisError}</p>}
            </div>
        </div>
    );
};

export default PlatDetail;
